import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { HeroStyles } from '../styles/GlobalStyles'
import SEO from '../components/SEO'
import BackgroundImage from '../components/BackgroundImage'
import bemWordmark from '../assets/logo/bem-wordmark.png'

const TaxisStyles = styled.div`
  padding: var(--spacing);
`

const TaxiStyles = styled.article`
  margin-bottom: 3rem;

  h2 {
    text-transform: uppercase;
    font-weight: bold;
    margin: var(--spacing) 0 1rem 0;
  }

  .details {
    margin: 0.5rem 0;

    span {
      font-weight: bold;
    }
  }
`

export default function TaxiPage({ data }) {
  // Hero image
  const taxiImage = data?.file?.childImageSharp?.gatsbyImageData

  // Taxi info
  const taxiData = data?.allGoogleSpreadsheetBemTaxi?.nodes

  return (
    <>
      <SEO title="Water Taxi" />
      <BackgroundImage imageData={taxiImage} underHeader fullHeight>
        <HeroStyles>
          <div>
            <img className="logo" src={bemWordmark} alt="Bird's Eye Maine" />
            <h1>Water Taxi</h1>
            <p>Affordable and reliable service in Casco Bay</p>
          </div>
        </HeroStyles>
      </BackgroundImage>
      <TaxisStyles>
        {taxiData?.map((taxi) => (
          <TaxiStyles key={taxi?.id}>
            <h2>{taxi?.destination}</h2>
            <div className="details">
              <span>Cost:</span> {taxi?.cost}
            </div>
            <div className="details">
              <span>Duration:</span> {taxi?.duration}
            </div>
          </TaxiStyles>
        ))}
      </TaxisStyles>
    </>
  )
}

export const query = graphql`
  {
    file(sourceInstanceName: { eq: "background" }, name: { eq: "taxi" }) {
      name
      childImageSharp {
        id
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90, formats: [AUTO, WEBP])
      }
    }
    allGoogleSpreadsheetBemTaxi {
      nodes {
        id
        destination
        cost
        duration
      }
    }
  }
`
